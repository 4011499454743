<template>
  <sign-page
    title-text="个人工作历史记录"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    table-size="large"
    @dataFileUpdate="dataFileUpdate"
    :tableActions="tableActions"
    :dealFormData="dealFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  hisWorkRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  methods: {
    async loadData () {
      let datas = await request.get()
      datas.forEach(v => v.isOver = v.endTime ? '是' : '否')
      return datas
    },
    async dataFileUpdate (parm, pageVm) {
      await pageVm.request.update(pageVm.chooseData.id, {
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.isOver === '否') {
        data.endTime = null
      }
      if (data.startTime && data.endTime && this.timeBj(data.startTime, data.endTime)) {
        this.$notice.info({
          title: '系统提示',
          desc: '结束时间需要大于开始时间。'
        })
        throw Error()
      }
      return data
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    workerList () {
      let data = this.$store.getters.workerList
      let need = this.$store.getters.userPrevilege.filter(v => v.objType === 'worker').map(v => v.objId)
      if (need && need.length > 0) {
        data = data.filter(v => need.includes(v.key))
      }
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.workerList,
          label: '员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '工作单位',
          key: 'orgName',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '担任职务',
          key: 'jobTitle',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否已结束',
          key: 'isOver',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '结束时间',
          key: 'endTime',
          show: (formData) => {
            return formData.isOver === '是'
          },
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          if (newItem.key === 'workerId') {
            newItem.fmDisabled = true
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '工作单位',
        field: 'orgName',
        sort: true
      },
      {
        title: '担任职务',
        field: 'jobTitle',
        sort: true
      },
      {
        title: '开始时间',
        field: 'startTime',
        sort: true,
        dataType: Date,
        width: 200,
        filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
        },
        text: (rowData) => {
          return rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-'
        }
      },
      {
        title: '结束时间',
        field: 'endTime',
        sort: true,
        dataType: Date,
        width: 200,
        filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '至今')
        },
        text: (rowData) => {
          return rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '至今'
        }
      }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
